<template>
  <section>
    <div class="itemInfoBox">
      <div class="itemTitle">
        {{venueInfo.supplierName}}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'itemCard',
  data () {
    return {
      venueInfo: {}
    }
  },
  created() {
    this.venueInfo = this.$SmartStorage.get('lossItem')
  },
  computed: {
  },
  methods: {
    currencyFormatter(amount, symbol = '￥', digits = 2) {
      return `${symbol}${Number(amount || 0).toFixed(digits)}`
    },
  }
}
</script>
<style lang="scss" scoped>
@import "venueCard";
</style>
