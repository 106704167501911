<template>
  <section>
    <div class="itemInfoBox teaBox">
      <div class="itemTitle">
        {{itemInfo.costType}}
        <div class="editBox" @click.stop="goDemandList()">
          <span>明细详情</span>
        </div>
      </div>
      <div class="priceInfo">
        <div>
          <div>备注信息</div>
          <span>{{itemInfo.descr || "暂无备注" }}</span>
        </div>
        <div>
          <div>{{ itemInfo.invoiceType }}</div>
          <p>
            <span>（{{ (itemInfo.price || 0) | currencyFormatter }} × {{ itemInfo.qty }}）</span>
            <span>{{(itemInfo.amount || 0) | currencyFormatter}}</span>
          </p>
        </div>
<!--        <div>-->
<!--          <div>{{ itemInfo.projectName }}</div>-->
<!--        </div>-->
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'teaCard',
  props: ['itemInfo'],
  data () {
    return {
    }
  },
  methods: {
    goDemandList () {
      this.$emit('costItemDetail', this.itemInfo)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "itemCard";
.teaBox {
  border-radius: .1rem;
}
.editBox {
  span {
    color: var(--themeColor);
    font-size: .15rem;
    display: inline-block;
    position: relative;
    width: 83px;
    height: 31px;
    border-radius: 0.04rem;
    overflow: hidden;
    text-align: center;
    line-height: 31px;
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: .1;
      left: 0;
      bottom: 0;
      background-color: var(--themeColor);
    }
  }
}
</style>
