<template>
  <section>
    <sm_header title='酒店损失' :smHleftEvent='closeWebview'></sm_header>
    <venue-card></venue-card>
    <div class="quotedPrice">
      <van-field label="损失总金额" :readonly="true" v-model="demandData.settleAmount">
        <template v-slot:left-icon>
          <svg color="#13161B" class="icon searchRowSvg" aria-hidden="true" style="width: .16rem;height: .16rem">
            <use xlink:href="#icon-feiyong"></use>
          </svg>
        </template>
      </van-field>
    </div>
    <div class="quotedPrice disabledPrice">
      <van-field label="报价" :readonly="true" v-model="demandData.quoteAmount">
        <template v-slot:left-icon>
          <svg color="#909399" class="icon searchRowSvg" aria-hidden="true" style="width: .16rem;height: .16rem">
            <use xlink:href="#icon-feiyong"></use>
          </svg>
        </template>
      </van-field>
    </div>
    <div v-for="(item, index) in demandData.itsSettleExpense" :key="index">
      <item-card :itemInfo="item" @costItemDetail="costItemDetail"></item-card>
      <div style="width: 100%;height: .08rem;background-color: #f8f8f8"></div>
    </div>

    <div class="fileTitle">
      水单
      <FinderFrame
          :gateway="gateway"
          :OrderByData="OrderByData"
          ref="FinderFrame"
          :isPreview="true"
          :h5FilePreview="false"
          :onlyPreview="true"
          :filterData="uploaderSettings.filterData"
          :mappingData="uploaderSettings.mappingData"
          :isMapping="true"
          :uploadTimeout="120000"
          @uploadCallback="() => {}"
      />
    </div>
    <div class="confirmCostFooter" v-show="isShowFooter">
      <div class="largeBtn2" v-for="(item, index) in btnList" :key="index" :style="{width: (90 / btnList.length)+ '%'}"
         @click="onceAgain(item)">{{item.actName}}</div>
    </div>
    <van-popup v-model="showBottom" position="bottom" :style="{ height: '100%' }">
      <sm_header title='退回' :smHleftEvent='() => {showBottom = false}'></sm_header>
      <van-field style="margin-top: .44rem;" v-model="message" rows="5" autosize
                 type="textarea" maxlength="400" placeholder="请输入退回原因" show-word-limit />
      <div class="largeBtn6" @click="launchEvent(selectBtn, message)">提交</div>
    </van-popup>
  </section>
</template>
<script>
import venueCard from "./components/venueCard";
import itemCard from "./components/itemCard";
import {FinderFrame} from "smart-filer-vue";
import "smart-filer-vue/lib/index.css";
import {getCookie} from "tiny-cookie";
import {Field, Popup} from 'vant';
export default {
  components: {
    itemCard,
    venueCard,
    FinderFrame,
    [Field.name]: Field,
    [Popup.name]: Popup,
  },
  data () {
    return {
      taskId: '',
      message: '',
      showBottom: false,
      selectBtn: {},
      costInnerItem: {},
      btnList: [{itsActData:{color:null}}],
      uploaderSettings: {
        mappingData: {
          processId: this.$SmartStorage.get("lossItem").proposalId,
          supplierId: this.$SmartStorage.get("lossItem").supplierId,
          relatedType: ["bill","bill_reback","SupplierSettle","settle"],
          catalog: ['损失费文件', '水单']
        },
        filterData: {
          processId: this.$SmartStorage.get("lossItem").proposalId,
          supplierId: this.$SmartStorage.get("lossItem").supplierId,
          relatedType: ["bill","bill_reback","SupplierSettle","settle"],
          catalog: ['损失费文件', '水单']
        }
      },
      demandData: {
        itsQuoteExpense: []
      },
      gateway: process.env.VUE_APP_GATEWAY,
      OrderByData: {
        catalog: 'asc',
        CreatedOn: 'asc'
      },
      tenant: getCookie('tenant') || this.$SmartStorage.get("tenant") || this.$SmartStorage.get("tenant_code")
    }
  },
  created () {
    this.initConfirmCost()
  },
  computed: {
    isShowFooter () {
      let result = this.btnList.length > 0 && [20,21].includes(this.demandData.lossStatus) && this.taskId
      return result
    }
  },
  methods: {
    initConfirmCost () {
      this.demandData = this.$SmartStorage.get('lossItem')
      this.taskId = this.demandData.taskId?this.demandData.taskId:false
      let isQueryTask = this.taskId && [42,50,53].includes(this.demandData.status)
      if(isQueryTask) {
        this.queryTaskActions(this.taskId)
      }
    },
    closeWebview () {
      this.$router.go(-1)
    },
    costItemDetail (item) {
      this.$SmartStorage.set('costInnerItem', item)
      this.$router.push({ path: "/costItemDetail"});
    },
    // footer
    async queryTaskActions(taskId) { // 获取按钮
      let params = {
        taskId: taskId
      };
      let res = await this.$service.QueryTaskActions(params);
      if (res && res.success) {
        this.btnList = res.content
      }
    },
    onceAgain(btn) { // 二次提示
      if(btn.actName=="退回") {
        this.selectBtn = btn
        this.showBottom = true
        this.message = ''
      } else{
        let msg = '是否确认对本酒店进行' + btn.actName+'操作'
        this.$dialog.confirm({
          message: msg,
        }).then(() => {
          this.launchEvent(btn)
        })
            .catch(() => {
            });
      }
    },
    launchEvent(btn,value) {
      switch(btn.actDirective){
        case "back-loss":
          this.rereport(value,btn.actionId)
          break;
        case "confirm-loss":
          this.acceptSettle(btn.actionId)
          break;
      }
    },
    // commen
    async rereport(value,actionId) { // footerBtn 退回==>流程接口
      let params = {
        "proposalId": this.demandData.proposalId,
        "supplierId": this.demandData.supplierId,
        "taskId": this.taskId,
        "actionId": actionId,
        "reason": value,
        "formData": JSON.stringify({supplierId: this.demandData.supplierId}),
        "taskData": JSON.stringify({supplierId: this.demandData.supplierId,'rebackReason':  value}),
        // 提交 1，退回报价 2，退回结算3，退回损失 4
        "operationType": 4
      }
      let res =await this.$service.creatTask(params);
      this.$store.commit('changeButStatus',true)
      if(res.success) {
        this.$toast('退回成功')
        this.$router.back()
      }
    },
    async acceptSettle(actionId) { // 确认结算
      let params = {
        "proposalId": this.demandData.proposalId,
        "supplierId": this.demandData.supplierId,
        "settle": this.demandData.settleAmount,
        "taskId": this.taskId,
        "actionId": actionId,
        "OperationType": 'acceptLoss',
      }
      let res = await this.$service.confirmSupplierSettle(params);
      if(res && res.success) {
        this.$toast('确认损失成功')
        this.$router.back()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.quotedPriceHead {
  font-size: .25rem;
  text-align: left;
  padding: 0.1rem 0.2rem;
  background: var(--themeColor);
  color: #ffffff;
  font-weight: bold;
}
.headTips {
  background: #F5F5F5;
  margin: .1rem;
  padding: .15rem .18rem;
  font-size: .12rem;
  border-radius: .04rem;
  max-height: 0.2rem;
  overflow: hidden;
  transition: max-height .5s ease;
  p {
    font-size: .12rem;
    text-align: left;
    color: #909399;
  }
  .tipsTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: .15rem;
    color: #13161B;
    margin-bottom: 0.2rem;
  }
}
.headTips.active{
  max-height: 2rem;
}
.fileTitle {
  text-align: left;
  font-size: .16rem;
  font-weight: bold;
  color: #000;
  padding: .1rem;
  position: relative;
  margin-top: .1rem;
}
.confirmCostFooter {
  position: fixed;
  bottom: 0;
  padding: 0.2rem 0.1rem 0.1rem 0.1rem;
  width: calc(100% - 0.2rem);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  .largeBtn2 {
    padding: 0.1rem 0;
    border-radius: 0.04rem;
    font-size: .17rem;
    background: #FFFFFF;
    border: 1px solid #E6E7E9;
    font-weight: 500;
    color: #90939A;
    &:last-child{
      background: var(--themeColor);
      border: 1px solid var(--themeColor);
      color: #FFFFFF;
    }
  }
}
</style>
<style lang="scss">
.confirmCostHead {
  .header-bg {
    opacity: 1 !important;
  }
}
.quotedPrice {
  margin: .08rem .1rem .05rem;
  border-radius: .04rem;
  .van-cell {
    background: #F5F5F5;
    .van-field__label {
      span {
        font-weight: bold;
      }
    }
    .van-field__control {
      text-align: right;
      font-weight: bold;
    }
  }
}
.disabledPrice {
  .van-cell {
    background: #F5F5F5;
    .van-field__label {
      span{
        color: #909399;
      }
    }
    .van-field__control {
      color: #909399;
    }
  }
}
</style>
